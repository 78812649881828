<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.opportunity.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <!--<CButton color="primary" v-on:click="createTableRow" v-if='checkPermission("create-opportunity")' style="margin-right: 10px;">{{ $lang.buttons.general.crud.create }} </CButton>-->
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Opportunity',self.userId)"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.startDate
                    }}</label>
                  <input
                      type="date"
                      :placeholder="$lang.opportunity.filter.startDate"
                      class="form-control"
                      v-model="opportunity.startDate"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.endDate
                    }}</label>
                  <input
                      type="date"
                      :placeholder="$lang.opportunity.filter.endDate"
                      class="form-control"
                      v-model="opportunity.endDate"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.industry
                    }}</label>
                  <v-select
                      :options="industryOptions"
                      v-model="opportunity.industry"
                      @option:selected="getSubIndustry"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.subIndustry
                    }}</label>
                  <v-select
                      :options="subIndustryOptions"
                      v-model="opportunity.subIndustry"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.duration
                    }}</label>

                  <v-select
                      :options="durationOptions"
                      v-model="opportunity.duration"
                  ></v-select>
                </div>
              </CCol>
              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{-->
              <!--                    $lang.opportunity.filter.priority-->
              <!--                  }}</label>-->
              <!--                  <v-select-->
              <!--                    :options="priorityOptions"-->
              <!--                    v-model="opportunity.priority"-->
              <!--                  ></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->
              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.profileVerified
                    }}</label>
                  <v-select
                      :options="profileVerifiedOptions"
                      v-model="opportunity.profileVerified"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.remoteWork
                    }}</label>
                  <v-select
                      :options="remoteWorkOptions"
                      v-model="opportunity.remoteWork"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="opportunity.user"
                  ></v-select>
                </div>
              </CCol>
              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{-->
              <!--                    $lang.opportunity.filter.currency-->
              <!--                  }}</label>-->
              <!--                  <v-select-->
              <!--                    :options="currencyOptions"-->
              <!--                    v-model="opportunity.currency"-->
              <!--                  ></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.opportunity.filter.visibility
                    }}</label>
                  <v-select
                      :options="visibilityOptions"
                      v-model="opportunity.visibility"
                  ></v-select>
                </div>
              </CCol>
              <!--              <CCol sm="6">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{-->
              <!--                    $lang.opportunity.filter.budget-->
              <!--                  }}</label>-->
              <!--                  <VueSimpleRangeSlider-->
              <!--                    style="width: 300px"-->
              <!--                    :min="0"-->
              <!--                    :max="1000000"-->
              <!--                    :logarithmic="true"-->
              <!--                    v-model="range"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </CCol>-->
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Opportunity',self.userId)"
          >
            <template #status="data">
              <span v-if="data.row.status === 'Active'">
                <CBadge :color="statusBudget(data.row.status)">Open</CBadge>
              </span>
              <span v-if="data.row.status !== 'Active'">
                <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
              </span>
            </template>
            <template #name="data">
              <span :title="data.row.name">{{
                  trimfunction(data.row.name, 75)
                }}</span>
            </template>
            <template #userWithCompany="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userWithCompany"
                  :length="20"
              />
            </template>
            <template #updatedAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.updatedAt) }}
            </template>

            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="editTableRow(data.row.id, data.row.userId)"
              >
                <i class="fas fa-eye"></i>
              </CButton>

              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.candidates"
                  v-on:click="candidateList(data.row)"

              >
                <i class="fas fa-user-friends"></i>
              </CButton>


              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  opportunity,
  industry,
  resourcetype,
  duration,
  currencytype,
  priority,
  users,
  skills,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OpportunitiesItem",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      iAmUser: "",
      messageColor: "success",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      visibilityOptions: [
        {
          value: 1,
          label: "Local",
        },
        {
          value: 2,
          label: "National",
        },
        {
          value: 3,
          label: "International",
        },
      ],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      module: opportunity,
      moduleIndustry: industry,
      moduleResource: resourcetype,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      modulePriority: priority,
      moduleSkills: skills,
      moduleUser: users,
      opportunity: {
        name: "",
        budget: "",
        isActive: "",
      },
      columns: [
        "userWithCompany",
        "name",
        "visibility",
        "ratingLabel",
        "status",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          userWithCompany: this.$lang.post.table.userName,
          ratingLabel: this.$lang.opportunity.table.information,
          name: this.$lang.opportunity.table.name,
          // budget: this.$lang.opportunity.table.budget,
          visibility: this.$lang.opportunity.table.visibility,
          status: this.$lang.opportunity.table.status,
          // createdAt: this.$lang.common.table.createdAt,
          updatedAt: this.$lang.common.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "company",
          "name",
          "visibility",
          "budget",
          "ratingLabel",
          "status",
        ],
        sortable: [
          "company",
          "name",
          "visibility",
          "budget",
          "ratingLabel",
          "status",
        ],
        filterable: [
          "company",
          "name",
          "visibility",
          "budget",
          "ratingLabel",
          "status",
          "actions",
        ],
      },
    };
  },

  created() {
    let self = this;
    self.listUrl = "/opportunities/list";
    self.userId = this.$route.params.user_id;
    if (typeof self.userId !== "undefined" || self.userId != null) {
      self.listUrl = this.listUrl +'/' +self.userId;
    } else {
      self.listUrl = this.listUrl;
    }
  },

  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.iAmUser = localStorage.getItem("iAmUser"); //hide some filter information.

    // self.userId = this.$route.params.user_id;
    // if (typeof self.userId !== "undefined" || self.userId != null) {
    //   self.listUrl = this.listUrl + self.userId;
    // } else {
    //   self.listUrl = this.listUrl;
    // }

    store.commit("showLoader", true); //Loading Image
    self.profileVerifiedOptions.push(
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"}
    );
    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get(this.listUrlApi(this.moduleDuration)).then((response) => {
      response.data.data.map(function (value, key) {
        self.durationOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleSkills)).then((response) => {
      response.data.data.map(function (value, key) {
        self.skillsOptions.push({value: value.id, label: value.title});
      });
    });
    // axios.get(this.listUrlApi(this.modulePriority)).then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.priorityOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    // axios.get(this.listUrlApi(this.moduleCurrency)).then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.currencyOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.name});
      });
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    candidateList(row) {
      this.$router.push({path: `/opportunities/candidates/${row.id}`});
    },
    getSubIndustry() {
      let self = this;
      if (self.opportunity.industry.value > 0) {
        const postData = {industry_id: self.opportunity.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },
    applyFilter() {
      let self = this;
      let postTypeId = "";
      let industry = "";
      let subIndustry = "";
      let duration = "";
      let priority = "";
      let profileVerified = "";
      let currency = "";
      let visibility = "";
      let remoteWork = "";
      let skills = "";
      let user = "";
      self.selectedSkills = [];
      if (this.opportunity.postTypeId != null) {
        postTypeId = this.opportunity.postTypeId.value;
      }
      if (this.opportunity.industry != null) {
        industry = this.opportunity.industry.value;
      }
      if (this.opportunity.subIndustry != null) {
        subIndustry = this.opportunity.subIndustry.value;
      }
      if (this.opportunity.resourceType != null) {
        resourceType = this.opportunity.resourceType.value;
      }
      if (this.opportunity.duration != null) {
        duration = this.opportunity.duration.value;
      }
      if (this.opportunity.priority != null) {
        priority = this.opportunity.priority.value;
      }
      if (this.opportunity.profileVerified != null) {
        profileVerified = this.opportunity.profileVerified.value;
      }
      if (this.opportunity.currency != null) {
        currency = this.opportunity.currency.value;
      }
      if (this.opportunity.visibility != null) {
        visibility = this.opportunity.visibility.value;
      }
      if (this.opportunity.remoteWork != null) {
        remoteWork = this.opportunity.remoteWork.value;
      }
      if (this.opportunity.user != null) {
        user = this.opportunity.user.value;
      }
      if (this.opportunity.skills != null) {
        skills = this.opportunity.skills;
        skills.map(function (value, key) {
          self.selectedSkills.push({
            value: value.value,
          });
        });
      }

      const reqObj = {
        postType: postTypeId,
        industry: industry,
        subIndustry: subIndustry,
        // resourceType: resourceType,
        duration: duration,
        priority: priority,
        profileVerified: profileVerified,
        currency: currency,
        visibility: visibility,
        remoteWork: remoteWork,
        user: user,
        fromDate: self.opportunity.startDate,
        toDate: self.opportunity.endDate,
        range: this.range,
      };
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.opportunity.postTypeId = {};
      this.opportunity.industry = {};
      this.opportunity.subIndustry = {};
      this.opportunity.duration = {};
      this.opportunity.priority = {};
      this.opportunity.profileVerified = {};
      this.opportunity.currency = {};
      this.opportunity.visibility = {};
      this.opportunity.remoteWork = {};
      this.opportunity.user = {};
      this.opportunity.startDate = {};
      this.opportunity.toDate = {};
      this.range = [0, 1000000];
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Opportunity Create"});
    },
    editTableRow(item, userId) {
      this.$router.push({path: `opportunities/view/${item}/${userId}`});
    },
    viewMediaTableRow(item, userId) {
      this.$router.push({path: `opportunities/media/list/${item}`});
    },
    viewResponseTableRow(item) {
      this.$router.push({path: `opportunities/response/list/${item}`});
    },
    viewReportTableRow(item) {
      this.$router.push({path: `opportunities/report/list/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
            store.commit("showLoader", false);
          })
          .catch(function (error) {
            store.commit("showLoader", false);
          });
    },
  },
};
</script>
